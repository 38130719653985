<template>
  <div class="rule-model">
    <div class="rule-width flex flex-c flex-align">
      <div class="container-box flex flex-c flex-align pos-rel">
        <img src="@/assets/img/cloud24.png"
             class="pos-abs more"
             alt="">
        <div class="container"
             :class="activeImg == 1 || activeImg == 2 ? 'two' : activeImg == 3 ? 'three':' '">
          <div class="flip"
               v-if="activeImg == 0">
            <div class="front">
              <img src="@/assets/img/cloud11.png"
                   alt="">
            </div>
            <div class="back">
              <img src="@/assets/img/cloud12.png"
                   alt="">
            </div>
          </div>
          <div class="flip"
               v-if="activeImg == 1">
            <div class="front">
              <img src="@/assets/img/cloud13.png"
                   alt="">
            </div>
            <div class="back">
              <img src="@/assets/img/cloud14.png"
                   alt="">
            </div>
          </div>
          <div class="flip"
               v-if="activeImg == 2">
            <div class="front">
              <img src="@/assets/img/cloud15.png"
                   alt="">
            </div>
            <div class="back">
              <img src="@/assets/img/cloud16.png"
                   alt="">
            </div>
          </div>
          <div class="flip"
               v-if="activeImg == 3">
            <div class="front">
              <img src="@/assets/img/cloud17.png"
                   alt="">
            </div>
            <div class="back">
              <img src="@/assets/img/cloud18.png"
                   alt="">
            </div>
          </div>
        </div>

      </div>

      <div class="yellow">
        <h1>参加艺考 <br><span class="blue">无缝衔接兴趣到专业</span> </h1>
        <h3>全球著名音乐教育团队全程参与课程研发</h3>
        <div class="listen-btn"
             @click="$emit('backBtn')">试听一下</div>
        <h4>
          得天独厚的资源<br>
          让真正的大师<br>
          就在你的身边
        </h4>
        <h5>
          我们讲究四两拨千金<br>
          带来的是从量到质的蜕变 <br>
        </h5>

        <div class="btn-list flex flex-align pos-rel">
          <img src="@/assets/img/cloud23.png"
               alt=""
               class="btn pos-abs"
               :style="`left:${activeImg * 120}px`">
          <img src="@/assets/img/cloud19.png"
               @click="activeImg = 0"
               alt="">
          <img src="@/assets/img/cloud20.png"
               @click="activeImg = 1"
               alt="">
          <img src="@/assets/img/cloud21.png"
               @click="activeImg = 2"
               alt="">
          <img src="@/assets/img/cloud22.png"
               @click="activeImg = 3"
               alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeImg: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
.container-box {
  width: 600px;
  height: 550px;
  .more {
    width: 100px;
    left: 0px;
    bottom: 250px;
    z-index: 1;
    height: 100px;
  }
}
.container {
  perspective: 1000;
  transform-style: preserve-3d;
  & {
    width: 420px;
    height: 550px;
  }
  &.two {
    width: 500px;
    height: 550px;
  }
  &.three {
    width: 600px;
    height: 550px;
  }

  .flip,
  .front,
  .back {
    width: 100%;
    height: 100%;
  }
  .flip {
    position: relative;
    transition: 1s;
    transform-style: preserve-3d;
  }
  .front,
  .back {
    position: absolute;
    top: 0px;
    left: 0px;
    backface-visibility: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .front {
    z-index: 2;
  }
  .back {
    transform: rotateY(-180deg);
  }
  &:hover .flip {
    transform: rotateY(180deg);
  }
}

h4 {
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 30px;
}
h5 {
  font-size: 18px;
  line-height: 30px;
}
.yellow {
  margin-left: 60px;
  margin-top: 30px;
  h1 span {
    color: #ffad40;
  }
  .red-border {
    color: #ffad40;
    border: 2px solid #ffad40;
    margin: 20px 0;
  }
  .btn-list {
    margin-top: 30px;
    img {
      width: 100px;
      height: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .btn {
      height: 40px;
      bottom: -10px;
    }
  }
}
.listen-btn {
  width: 126px;
  height: 40px;
  text-align: center;
  margin: 20px 0;
  font-size: 20px;
  line-height: 36px;
  border: 2px solid #ffad40;
  color: #ffad40;
  cursor: pointer;
  border-radius: 4px;
}
</style>