<template>
  <Layout class="pos-rel">
    <div class="banner"
         v-if="bannerBg"
         :style="`background-image:url(${bannerBg})`">

    </div>
    <img class="banner-btn"
         src="@/assets/img/cloud25.png"
         alt=""
         v-if="videoBtn"
         @click="videoBox = true">

    <div class="class-box flex flex-c"
         v-show="courseBox">
      <GetCourse ref="GetCourse"></GetCourse>
    </div>

    <div class="video-box"
         @mousewheel.prevent
         v-if="videoBox">
      <div class="close">
        <video autoplay
               controls
               loop
               src="http://lesson.cloudnotes.com.cn/video/video.mp4">
        </video>
        <img src="@/assets/img/relbtn4.png"
             alt=""
             @click="videoBg">
      </div>

    </div>

    <div class="trans-box"
         :style="{marginTop:`${translateHeight}px`}">

      <div class="rule-limit">
        <h1 class="tx-c">钢琴启蒙 <span class="blue">启得飞快</span> </h1>
        <h3 class="tx-c">兴趣一节课就来</h3>
        <div class="listen-btn"
             @click="$refs.GetCourse.classBox = true;courseBox = true">试听一下</div>
        <div class="piano rule-width">
          <div class="left flex flex-align pos-rel">
            <img class="one pos-abs"
                 src="@/assets/img/cloud2-5.png"
                 alt="">
            <img class="img"
                 src="@/assets/img/cloud2.png"
                 alt="">
            <div>
              <h2 class="flex flex-align">
                <img src="@/assets/img/cloud2-4.png"
                     alt="">
                直播教学
              </h2>
              <h5>
                多元场景 智能互动 <br>
                创新融合教学理念 定制个性化教学服务<br>
                专属顾问全程陪护 钢琴练习随学随温
              </h5>
            </div>
          </div>
          <div class="right flex flex-r flex-align pos-rel">
            <img src="@/assets/img/cloud2-7.png"
                 class="one pos-abs"
                 alt="">
            <img src="@/assets/img/cloud2-6.png"
                 class="two pos-abs"
                 alt="">
            <div>
              <h2 class="flex flex-align">
                <img src="@/assets/img/cloud2-3.png"
                     alt="">
                直播教学
              </h2>
              <h5>
                指尖的数字魔法 动画式互动剧情<br>
                寓教于乐 学练一体<br>
                AI萌宠云团子陪同闯关<br>
                体验异世界的音乐冒险
              </h5>
            </div>
            <img class="img"
                 src="@/assets/img/cloud2-2.png"
                 alt="">
          </div>

        </div>
      </div>

      <div class="rule-model-bg red">
        <div class="rule-limit flex flex-c flex-align">
          <div>
            <h1>考级学习<br>
              <span class="red">我们比应试更强</span>
            </h1>
            <h3>全面覆盖国内外一线考级机构</h3>
            <div class="listen-btn red-border"
                 @click="$refs.GetCourse.classBox = true;courseBox = true">试听一下</div>
            <h4>
              打破传统学习壁垒<br>
              用复合式 组合式 多元化 个性化的<br>
              美育方式<br>
              让音乐学习<br>
              更加高效 系统 全面 专业
            </h4>
            <h5>
              螺旋上升的科学训练<br>
              全方位培养孩子的乐感和演奏技巧<br>
              让孩子自信表演<br>
              轻松应对考核
            </h5>
          </div>
          <img src="@/assets/img/cloud3.png"
               alt="">
        </div>
      </div>

      <TurnBox @backBtn="$refs.GetCourse.classBox = true;courseBox = true"></TurnBox>

      <div class="rule-model-bg orange">
        <h1 class="tx-c">学习效果要好 <span>更让家长省心</span>
        </h1>
        <h3 class="tx-c">上课时间就是家长放松时间</h3>
        <div class="listen-btn orange-border"
             @click="$refs.GetCourse.classBox = true;courseBox = true">试听一下</div>
        <div class="rule-width flex flex-c">
          <div>
            <img class="left-img"
                 src="@/assets/img/cloud5.png"
                 alt="">
            <h4>
              因材施教<br>
              集聆听、表达和交流为一体的<br>
              场景化教学模式
            </h4>
            <h5>
              帮助学员突破表演与技术难点<br>
              加强综合表演能力<br>
              多渠道测评分析及反馈<br>
              辅导完成学习进度和目标
            </h5>
          </div>
          <img src="@/assets/img/cloud4.png"
               alt=""
               class="right-img">
        </div>
      </div>

      <div class="rule-limit ">
        <h1 class="tx-c">有趣的在线课堂 <span class="purple">孩子学得更开心</span> </h1>
        <h3 class="tx-c">让学习变成爱好</h3>
        <div class="listen-btn purple-border"
             @click="$refs.GetCourse.classBox = true;courseBox = true">试听一下</div>
        <div class="rule-width flex flex-c">
          <img src="@/assets/img/cloud6.png"
               alt="">
        </div>
      </div>

      <div class="join rule-model-bg">
        <h1 class="tx-c model-title">云音符的承诺</h1>
        <h3 class="tx-c">选择我们是个好决定</h3>
        <div class="rule-width flex flex-sb tx-c">
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/cloud7.png"
                   alt="">
            </div>
            <h3>服务承诺</h3>
            <p>根据孩子特点匹配课程与老师<br>
              专属助教全程提供五星服务<br>
              为家长解决各种问题</p>
          </div>
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/cloud8.png"
                   alt="">
            </div>
            <h3>师资承诺</h3>
            <p>所有老师均拥有音乐院校<br>
              师范院校本科/研究生背景<br>
              经过层层筛选与培训后上岗</p>
          </div>
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/cloud9.png"
                   alt="">
            </div>
            <h3>安全承诺</h3>
            <p>设立云音符用户专项基金<br>
              为用户课费安全保驾护航<br>
              资金储备有保障的在线教育平台</p>
          </div>
          <div class="box">
            <div class="img flex flex-c flex-align">
              <img src="@/assets/img/cloud10.png"
                   alt="">
            </div>
            <h3>退款承诺</h3>
            <p>对课程不满意 对老师不满意<br>
              对效果不满意<br>
              家长随时可以提出退款要求</p>
          </div>
        </div>
      </div>

    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/index'
import bannerBg from '@/assets/img/cloud1.png'
import animated from '@/utils/animated.js'
import TurnBox from './components/turn'
import GetCourse from '@/components/relationBox/components/getCourse'

export default {
  components: {
    Layout,
    TurnBox,
    GetCourse,
  },
  data() {
    return {
      bannerBg: bannerBg,
      courseBox: false,
      videoBox: false,
      videoBtn: true,
    }
  },
  mixins: [animated],
  methods: {
    videoBg(event) {
      event.stopPropagation()
      this.videoBox = false
    },
  },
  watch: {
    scroll: {
      handler(val) {
        if (val > this.scrollNum) {
          this.translateHeight = 370
          this.videoBtn = false
        } else {
          this.translateHeight = 1080
          this.videoBtn = true
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 1080px;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
}
.video-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .close {
    position: relative;
    width: 75%;
    margin-top: 2vh;
    border-radius: 10px;
    overflow: hidden;
    video {
      width: 100%;
      height: auto;
    }
    img {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}
.banner-btn {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: calc(50% - 50px);
  margin-top: 700px;
  display: block;
  cursor: pointer;
  z-index: 0;
  transition: 1.6s ease;
}
.trans-box {
  background: #fff;
  transition: 1.6s ease;
}

.class-box {
  position: fixed;
  left: calc(50% - 150px);
  top: calc(50% - 200px);
  width: 300px;
  height: 400px;
  z-index: 99;
}
.piano {
  h2 {
    font-weight: bold;
    img {
      width: 72px;
      height: 72px;
      margin-right: 10px;
    }
  }
  h5 {
    margin-left: 82px;
    margin-top: 10px;
  }
  .left {
    .one {
      left: 520px;
      top: 80px;
      width: 210px;
      height: 90px;
    }
    h5 {
      margin-bottom: 50px;
    }
    .img {
      width: 540px;
      margin-right: 100px;
    }
  }
  .right {
    margin-top: -236px;
    .one {
      left: 410px;
      bottom: 44px;
      width: 110px;
      height: 116px;
    }
    .two {
      right: -40px;
      top: 0px;
      width: 130px;
      height: 160px;
    }
    h2 {
      margin-top: 180px;
    }
    .img {
      width: 660px;
      margin-left: 70px;
      margin-right: 50px;
    }
  }
}
h1 {
  margin-bottom: 10px;
}
.listen-btn {
  width: 126px;
  height: 40px;
  text-align: center;
  margin: 20px auto;
  font-size: 20px;
  line-height: 36px;
  border: 2px solid #40b4fc;
  color: #40b4fc;
  cursor: pointer;
  border-radius: 4px;
}
.purple {
  color: #8c59c2;
}
.purple-border {
  border: 2px solid #8c59c2;
  color: #8c59c2;
}
h4 {
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 30px;
}
h5 {
  font-size: 18px;
  line-height: 30px;
}
.red {
  h1 span {
    color: #de5636;
  }
  .red-border {
    color: #de5636;
    border: 2px solid #de5636;
    margin: 20px 0;
  }
  img {
    width: 600px;
    margin-left: 100px;
  }
}
.orange {
  h1 span {
    color: #ff7656;
  }
  .orange-border {
    color: #ff7656;
    border: 2px solid #ff7656;
  }
  .left-img {
    width: 260px;
    margin-bottom: 120px;
  }
  .right-img {
    width: 740px;
    margin-left: 50px;
  }
}

.join {
  .box {
    .img {
      height: 120px;
      margin-top: 50px;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
</style>
